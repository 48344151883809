import { tabs } from "../contexts";
import { Tab } from "../types";

// @ts-ignore
import darkPlaceholder from '../assets/images/placeholder-dark.png';
// @ts-ignore
import lightPlaceholder from '../assets/images/placeholder.png';

export enum DeviceType {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Desktop = 'Desktop',
    Unknown = 'Unknown',
}

export function redirectToHomePage() {
    window.location.href = window.location.origin;
}

export function chromeSendMessage(message: any, callback: any) {
    // @ts-ignore
    if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
        const extensionId = 'ehlpnjcddkggjcbeonecfdfdbeiiopoh';
        // @ts-ignore
        chrome.runtime.sendMessage(extensionId, message, callback);
    } else {
        console.warn('chrome.runtime.sendMessage is not available.');
    }
}

export const beMapper: { [key: string]: string; } = {
    'All': 'all',
    'Sign Up': 'signup',
    'Game': 'game',
    'App': 'app',
    'Surevy': 'survey',
    'Deposit': 'deposit',
    'Purchase': 'purchase',
    'Quiz': 'quiz',
    'Survey': 'survey',
    'Others': 'others',
    'win': 'windows',
    'and': 'android',
    'mac': 'macos',
    'ios': 'iphone',
    'Highest Reward': 'desc',
    'Lowest Reward': 'asc',
    'Popular': 'desc',
};

export function currencyFormat(amount: number, code: string) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: code,
    });

    return formatter.format(amount);
}

export const sanitizeString = (value: string | undefined): string => {
    if (!value) return '';
    const htmlParser = new DOMParser();
    const doc = htmlParser.parseFromString(value, 'text/html');
    return doc.body.textContent ?? '';
};

export interface Event {
    name: string;
    payout: number;
}

export function getPlaceHolderSrc() {
    if (document.documentElement.hasAttribute('data-theme')) {
        return darkPlaceholder;
    }
    return lightPlaceholder;
}

export const sanitizeCategory = (categories: string[]): string => {
    if (categories.length > 0 && categories[0] !== 'Android') return categories[0];
    else if (categories.length > 1) return categories[1];
    else if (categories.length > 0) return categories[0];
    return '';
};

export function defaultTab(): Tab {
    const tabName = new URL(window.location.href).searchParams.get('category') || 'All';
    return tabs.find(tab => tab.name.toLowerCase() == tabName.toLowerCase()) || tabs[0];
}

export function extractRedirectUrl(url: string): string {
    const parsedUrl = new URL(url);
    const params = new URLSearchParams(parsedUrl.search);
    const redirUrl = params.get("redir_url");

    const decodedRedirUrl = redirUrl ? decodeURIComponent(redirUrl) : 'https://santabrowser.com';

    return decodedRedirUrl;
}

export function getDeviceType(): DeviceType {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    // Check for Mobile devices
    if (/android/i.test(userAgent)) {
        return DeviceType.Mobile;
    }

    // @ts-ignore
    if (/iPhone|iPad|iPod/i.test(userAgent) && !window.MSStream) {
        return DeviceType.Mobile;
    }

    // Check for Tablet devices
    if (/iPad|tablet/i.test(userAgent)) {
        return DeviceType.Tablet;
    }

    // Check for Desktop devices
    // Assuming all non-mobile and non-tablet devices are desktops
    return DeviceType.Desktop;
}
