import { CenteredInfo } from "../styles/HomePageStyles";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import {
    OfferContainerSlider,
    SliderButton,
    StoreSliderNavigation,
    ViewAll
} from "../styles/WallStyles";
import { WallProps } from "../types";
import { LeftIcon, RightIcon } from "./icons";
import OfferWallWrapper from "./Offer";
import React from "react";

export default function WallSlider(props: WallProps) {
    const swiperRef = React.useRef<any>();
    const [slideBegin, setSlideBegin] = React.useState(true);
    const [slideEnded, setSlideEnded] = React.useState(false);
    const [showMore, setShowMore] = React.useState(false);

    React.useEffect(() => {
        setShowMore(!(slideBegin && slideEnded));
    }, [slideBegin, slideEnded]);

    if (props.offers === undefined) {
        return <CenteredInfo>Loading...</CenteredInfo>;
    }

    const breakpoints = {
        0: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        540: {
            slidesPerView: 3.5,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 4,
        },
        1024: {
            slidesPerView: 4.5,
        },
        1280: {
            slidesPerView: 5,
        },
        1440: {
            slidesPerView: 6,
        },
        1536: {
            slidesPerView: 7,
        },
        1920: {
            slidesPerView: 8,
        },
    };

    return (
        <OfferContainerSlider>
            <>
                {props.offers?.length > 0 && showMore ? (
                    <StoreSliderNavigation>
                        <SliderButton disabled={slideBegin} onClick={() => swiperRef.current?.slidePrev()}><LeftIcon /></SliderButton>
                        <SliderButton disabled={slideEnded} onClick={() => swiperRef.current?.slideNext()}><RightIcon /></SliderButton>
                    </StoreSliderNavigation>
                ) : null}

                <ViewAll onClick={() => swiperRef.current?.slideNext()}>View more</ViewAll>
            </>

            <Swiper
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                    setSlideBegin(swiper.isBeginning);
                    setSlideEnded(swiper.isEnd);
                }}
                onAfterInit={(swiper) => {
                    setSlideEnded(swiper.isEnd);
                }}
                modules={[Navigation]}
                spaceBetween={20}
                breakpoints={breakpoints}
                onSlideChange={(swiper) => {
                    setSlideBegin(swiper.isBeginning);
                    setSlideEnded(swiper.isEnd);
                }}
            >
                {props.offers
                    ?.filter(({ payout }) => payout > 0)
                    .map((offer, index) => (
                        <SwiperSlide>
                            <OfferWallWrapper
                                key={index}
                                platform={props.platform}
                                mini={props.mini}
                                offer={offer}
                                flat={props.flat}
                                singleRow={props.singleRow}
                                onClickOffer={props.onClickOffer}
                                width={props.width}
                            />
                        </SwiperSlide>
                    ))}
            </Swiper >
        </OfferContainerSlider>
    );
}
