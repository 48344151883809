import styled from 'styled-components';
import { redirectToHomePage } from '../utils';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  background-color: var(--sww-bg-color);
  z-index: 999;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 600px;
  text-align: center;
  color: var(--sww-text-color);

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Button = styled.button`
  height: 40px;
  border-radius: 6px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600px;
  color: var(--sww-btn-color);
  background: var(--sww-btn-background);
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 12px;
    height: 36px;
    padding: 0 16px;
  }
`;

const SomethingWentWrong = () => {
  return (
    <Container>
      <Message>
        <Text>Something went wrong!</Text>
        <Button onClick={redirectToHomePage}>Go to Home</Button>
      </Message>
    </Container>
  );
};

export default SomethingWentWrong;
