import styled from "styled-components";

interface AdBannerProps {
    src: string;
    alt: string;
    link?: string;
}

const Banner = styled.div`
    width: 100%;
    overflow: hidden;
    border-radius: 6px;

    img {
        width: 100%;
        height: fit-content;
        min-height: 100px;
        object-fit: cover;
    }
`;

const AdBannerStyle = styled.div`
    margin-top: 16px;    
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
`;

const AdBanner = ({ src, alt, link }: AdBannerProps) => (
    <AdBannerStyle>
        {link ? (
            <a href={link}>
                <Banner><img src={src} alt={alt} /></Banner>
            </a>
        ) : (
            <Banner><img src={src} alt={alt} /></Banner>
        )}
    </AdBannerStyle>
);


export default AdBanner;