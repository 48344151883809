import { Container, Title, Desc, Image, Button } from '../styles/ErrorPageStyles';
import { redirectToHomePage } from '../utils';

// @ts-ignore
import errorImg from '../assets/images/error-page.png';

export default function ErrorPage({ errorMessage }: { errorMessage?: string }) {
  return (
    <Container>
      <div>
        <div>
          <Title>Oops!</Title>
          <Desc>{errorMessage || 'Something went wrong'}</Desc>
        </div>

        <Image src={errorImg} alt='error-page' />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button role='button' onClick={redirectToHomePage}>
            Refresh
          </Button>
        </div>
      </div>
    </Container>
  );
}
