import { useState, useEffect } from "react";

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
            window.requestAnimationFrame((): void | undefined => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                setIsMobile(window.innerWidth <= 768);
            });
        };
        const resizeObserver = new ResizeObserver(observerCallback);
        resizeObserver.observe(document.body);
    }, []);

    return isMobile;
}