import {
    newTracker,
    trackPageView,
    enableActivityTracking,
    BrowserTracker,
} from "@snowplow/browser-tracker";
import React from "react";
import { useLocation } from "react-router-dom";

let tracker: BrowserTracker | null | undefined;

const initializeTracker = (endpoint: string) => {
    tracker = newTracker("playwall", endpoint, {
        appId: "playwall-pageviews",
        plugins: [],
    });

    enableActivityTracking({
        minimumVisitLength: 5,
        heartbeatDelay: 5,
    });
};

const useTrackPageview = () => {
    const location = useLocation();
    React.useEffect(() => {
        trackPageView();
    }, [location]);
};

const isTrackerInitialized = () => tracker !== undefined;

export { tracker, initializeTracker, useTrackPageview, isTrackerInitialized };