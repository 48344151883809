/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from "styled-components";

const popup = css`
    width: 100%;
    display: grid;
    gap: 0.5rem;
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
    }
`;

const FullPageDialog = styled.dialog`
    margin: auto;
    position: relative;
    border-radius: 0.5rem;
    background-color: var(--popup-bg);
    border: none;
    width: 820px;
    max-width: calc(100% - 20px);
    overflow: hidden !important;

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        overflow: hidden;
        position: fixed;
    }

    @media (max-width: 768px) {  
        max-width: calc(100% - 32px);
        height:  calc(100% - 20px);
    }
`;

const Form = styled.form<{ css: any; }>`
    ${({ css }) => css}

    padding: 20px 30px;
    padding-right: 20px;

    @media (max-width: 768px) {
        padding: 10px 20px;
        padding-right: 10px;
    }
`;

const PopupCloseButton = styled.button`
    position: absolute;
    background: var(--popup-close-bg);
    top: 10px;
    right: 10px;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    outline: none;
`;

const PopupOfferTitle = styled.h2`
    color: var(--text-normal);
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 0;
    padding-right: 24px;

    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    width: calc(100% - 30px);
`;
const PopupOfferPrice = styled.h2`
    color: var(--text-dimmed);
    font-size: 1.87rem;
    font-weight: 600;
`;

const PopupRightContent = styled.h2`
    padding: 0 1rem;
    width: 400px;
    max-width: 100%;
    max-width: 100%;

    @media (max-width: 768px) {
        width: unset;
        padding: 0;
    }
`;

const PopUpScroll = styled.div`
  height: 70vh;
  background: var(--popup-bg);
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  @media (max-width: 768px) {
    height: 100%;
  }
`;

const OfferPopupHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-bottom: 1rem;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const OfferImage = styled.img`
    max-width: 156px;
    height: 156px;
    object-fit: cover;
    border-radius: 5px;

    @media (max-width: 768px) {
        width: 99px;
        height: 100px;
    }
`;

const OfferPopupMeta = styled.div`
    display: flex;
    flex-direction: row;
`;

const OfferCategories = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.5rem 0;
`;

const OfferProvider = styled.span`
    background-color: var(--popup-provider-bg);
    color: var(--popup-provider-color);
    border-radius: 50vmax;

    font-size: 10px;
    font-style: normal;
    font-weight: normal;

    padding: 2px 14px;
`;

const OfferDescription = styled.p`
    margin: 0.5rem 0;
    margin-bottom: 24px;
    color: var(--text-dimmed);
    font-size: 1rem;
    font-weight: 400;
    padding: 0 0.5rem;

    @media (max-width: 768px) {
        font-size: 0.8rem;
        padding: 0;
    }
`;

const OfferPopupBodyWrapper = styled.div`
`;

const OfferPopupBody = styled.div<{ single: boolean; }>`
    display: grid;
    grid-template-columns: ${props => props.single ? null : '1fr auto'};
    justify-content: ${props => props.single ? 'center' : null};
    gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 100%;
    }
`;

const OfferEventsList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style: none;
    margin: 0;
    border-radius: 0.625rem;
    padding: 0.5rem;

    @media (max-width: 768px) {
        padding: 0.6rem 0;
    }
`;

const OfferEvent = styled.li`
    display: grid;
    grid-template-columns: auto 1fr; 
    gap: 20px;
    align-items: center;

    @media (max-width: 768px) {
        gap: 10px;
    }
`;

const OfferEventRewardsPillContainer = styled.li`
    display: flex;
    width: 4rem;
`;

const OfferEventRewardsPillDescription = styled.li`
    color: var(--text-dimmed);
    font-size: 0.8rem;
`;

const OfferPopupStepTitle = styled.h2`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--text-normal);
    margin-top: 20px;
`;

const OfferPopupStepText = styled.p`
    margin-top: 4px;
    font-weight: 500;
    font-size: 0.775rem;
    line-height: 1.2rem;
    color: var(--text-dimmed);
`;

const QRCodeWrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;
  height: 314px;
`;

const QrCodeDecriptionText = styled.p`
    color: var(--text-normal);
    font-size: 10px;
    margin-bottom: 0.5rem;
`;

const PopupOfferCategory = styled.span`
    font-weight: 500;
    align-self: center;
    background-color: var(--popup-tag-bg);
    border-radius: 50vmax;
    color: var(--popup-tag-color);
    font-size: 10px;
    padding: 0.1rem 0.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
`;

const OfferEventRewardsPill = styled.li`
    padding: 0 0.4rem;
    background: var(--popup-rewards-bg);
    font-size: 0.8rem;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: center;
    min-width: 63px;
    min-height: 24px;
    border-radius: 5px;
    border: var(--popup-rewards-border);
    background: var(--popup-rewards-bg);
    color: var(--popup-rewards-color);

    @media (max-width: 768px) {
        padding: 0 0.3rem;
    }
`;

const OfferPopupQrCode = styled.div`
    display: grid;
    place-items: center;
    height: fit-content;
    grid-template-rows: auto auto 1fr;
    background-color: var(--popup-qr-bg);
    border: var(--popup-qr-border);
    padding: 10px;
    border-radius: 6px;
    width: fit-content;
    min-width: 180px;
    max-width: 100%;
`;

const ConfirmActionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
`;

const ConfirmAction = styled.button`
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: var(--popup-activate-bg);
    border-radius: 5px !important;
    color: var(--popup-activate-color);
    box-shadow: var(--popup-activate-shadow);
    font-size: 14px;
    font-weight: 500;
    border: none;
    width: 306px;
    max-width: 100%;
    outline: none;
    cursor: pointer;
`;

export {
    popup,
    Form,
    FullPageDialog,
    OfferPopupHeader,
    PopupCloseButton,
    OfferPopupMeta,
    OfferImage,
    PopUpScroll,
    PopupRightContent,
    PopupOfferPrice,
    PopupOfferTitle,
    OfferCategories,
    OfferDescription,
    OfferProvider,
    OfferPopupBodyWrapper,
    PopupOfferCategory,
    OfferPopupBody,
    OfferEventsList,
    OfferEvent,
    OfferEventRewardsPillContainer,
    OfferEventRewardsPillDescription,
    OfferPopupStepTitle,
    OfferPopupStepText,
    QRCodeWrapper,
    QrCodeDecriptionText,
    OfferEventRewardsPill,
    OfferPopupQrCode,
    ConfirmActionWrapper,
    ConfirmAction
};