/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
    SubTitle,
} from "../styles/HomePageStyles";
import {
    popup,
    Form,
    FullPageDialog,
    OfferPopupHeader,
    PopupCloseButton,
    OfferPopupMeta,
    OfferImage,
    PopUpScroll,
    PopupRightContent,
    PopupOfferCategory,
    PopupOfferPrice,
    PopupOfferTitle,
    OfferCategories,
    OfferDescription,
    OfferProvider,
    OfferPopupBodyWrapper,
    OfferPopupBody,
    OfferEventsList,
    OfferEvent,
    OfferEventRewardsPillContainer,
    OfferEventRewardsPillDescription,
    OfferPopupStepTitle,
    OfferPopupStepText,
    QRCodeWrapper,
    OfferEventRewardsPill,
    QrCodeDecriptionText,
    OfferPopupQrCode,
    ConfirmAction,
    ConfirmActionWrapper
} from "../styles/OfferPopupStyles";
import { OfferItem } from "../types";
import { QRCode } from 'react-qrcode-logo';
// @ts-ignore
import logoImage from '../assets/images/santa-logo.png';
import { currencyFormat, DeviceType, Event, getDeviceType, getPlaceHolderSrc, sanitizeString, chromeSendMessage } from "../utils";
import { CloseIcon } from "./icons";
import { usePlaywallContext } from "../contexts";

export default function OfferPopup({
    offer,
    setOffer,
}: {
    offer?: OfferItem;
    setOffer: (offer?: OfferItem) => void;
}) {
    const deviceType = getDeviceType();

    const { activePlatform } = usePlaywallContext();

    if (offer === undefined) {
        return null;
    }

    return (
        <ComponentPopup css={popup} open onClose={() => setOffer(undefined)}>
            <PopupOfferTitle>
                {sanitizeString(offer.title)}
            </PopupOfferTitle>

            <PopUpScroll className="force_scroll_style">
                <OfferPopupHeader>
                    <OfferImage src={offer.imageUrl} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = getPlaceHolderSrc();
                    }} />
                    <PopupRightContent>
                        <PopupOfferPrice>
                            {currencyFormat(offer.payout, offer.currency)}
                        </PopupOfferPrice>
                        <OfferPopupMeta>
                            <OfferProvider>{offer.provider}</OfferProvider>
                        </OfferPopupMeta>
                        <OfferCategories>
                            {offer?.categories?.length && offer.categories?.map((category, index) => (
                                <PopupOfferCategory key={index}>{category}</PopupOfferCategory>
                            ))}
                        </OfferCategories>
                        <OfferDescription>
                            {sanitizeString(offer.description)}
                        </OfferDescription>
                    </PopupRightContent>
                </OfferPopupHeader>
                <OfferPopupBodyWrapper>
                    <OfferPopupBody single={!offer?.events?.length}>
                        {offer?.events?.length && <div>
                            <SubTitle>Rewards</SubTitle>

                            <OfferEventsList>
                                {offer.events && offer.events.map((event: Event, index: number) => (
                                    <OfferEvent key={index}>
                                        <OfferEventRewardsPillContainer>
                                            <OfferEventRewardsPill>{currencyFormat(event.payout, offer.currency)}</OfferEventRewardsPill>
                                        </OfferEventRewardsPillContainer>
                                        <OfferEventRewardsPillDescription>{event.name}</OfferEventRewardsPillDescription>
                                    </OfferEvent>
                                ))}
                            </OfferEventsList>

                            {offer.terms && (
                                <>
                                    <OfferPopupStepTitle>Steps</OfferPopupStepTitle>
                                    <OfferPopupStepText>{offer?.terms}</OfferPopupStepText>
                                </>
                            )}
                        </div> || <div></div>}


                        {(deviceType === DeviceType.Desktop && activePlatform.id !== 'win') && (
                            <OfferPopupQrCode>
                                <QrCodeDecriptionText>{'Scan to Redeem'}</QrCodeDecriptionText>
                                <QRCodeWrapper>
                                    <QRCode
                                        value={offer?.santaClickUrl}
                                        logoImage={logoImage}
                                        logoPadding={1}
                                        logoPaddingStyle="circle"
                                        size={300}
                                    />
                                </QRCodeWrapper>
                            </OfferPopupQrCode>
                        ) || <div></div>}
                    </OfferPopupBody>
                </OfferPopupBodyWrapper>
            </PopUpScroll>

            {(deviceType !== DeviceType.Desktop || activePlatform.id === 'win') && (
                <ConfirmActionWrapper>
                    <ConfirmAction onClick={(e) => {
                        e.stopPropagation();

                        const deviceType = getDeviceType();

                        if (deviceType === DeviceType.Desktop) {
                            window.open(offer.santaClickUrl, '_blank',);
                            // chromeSendMessage({ message: 'openLinkInNewTab', url: offer.santaClickUrl }, () => { });
                        } else {
                            window.open(offer.santaClickUrl, '_blank',);
                        }
                    }}> Earn {currencyFormat(offer.payout, offer.currency)}
                    </ConfirmAction>
                </ConfirmActionWrapper>
            )}

            <PopupCloseButton>
                <CloseIcon fill="var(--popup-close-color)" />
            </PopupCloseButton>
        </ComponentPopup>
    );
}

const ComponentPopup: React.FC<{
    children: React.ReactNode;
    css?: any;
    open?: boolean;
    onClose?: () => void;
}> = (props) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [open, _] = React.useState(props.open);
    const dialogRef = React.useRef<HTMLDialogElement>(null);

    const formRef = React.useRef<HTMLFormElement>(null);

    const handleClickOutside = (event: any) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            if (props.onClose) props.onClose();
        }
    };

    React.useEffect(() => {
        if (dialogRef.current) {
            if (open) {
                document.addEventListener('mousedown', handleClickOutside);
                dialogRef.current.showModal();
            } else {
                dialogRef.current.close();
            }
        }

        // Cleanup the event listener when the component unmounts or the dialog closes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, dialogRef]);

    return (
        <FullPageDialog ref={dialogRef} onClose={props.onClose}>
            <Form css={props.css} method="dialog" ref={formRef}>
                {props.children}
            </Form>
        </FullPageDialog>
    );
};