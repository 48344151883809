import { platforms, sortbys, tabs, usePlaywallContext } from './contexts';
import { BodyContainer } from './styles/HomePageStyles';
import AppHeader from './components/AppHeader';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import { earnings_route, ROUTE_CONFIG } from './route-config';
import SideBar from './components/SideBar';
import styled from 'styled-components';
import {
  CloseIconWrapper,
  DeviceDowndropItem,
  FilterContainer,
  Search,
  SearchBox,
  SortDowndrop,
  SortDowndropItem,
  SortDowndropItemIcon,
  SortDowndropItemName,
  SortDowndropItems,
  SortDowndropWrapper,
} from './styles/AppHeaderStyles';
import { CloseIcon, LeftIcon, SearchIcon } from './components/icons';
import { Earnings } from './types';
import React from 'react';
import SplashScreen from './components/SplashScreen';

const StickySearchStyle = styled.div<{ top: string }>`
  position: sticky;
  background: var(--full-bg);
  top: ${(props) => props.top};
  z-index: 2;
`;

export const DesktopView = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

export const Spacer = styled.div<{ height: string }>`
  height: ${(props) => props.height};
`;

const BackActionStyle = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BackActionText = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: var(--action-back-text-color);
`;

const BackActionIcon = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: var(--action-back-icon-bg);
  box-shadow: var(--action-back-icon-shadow);
  padding: 4px;
`;

const BackAction = () => {
  return (
    <BackActionStyle onClick={() => window.history.back()}>
      <BackActionIcon>
        <LeftIcon fill={'var(--action-back-icon-color)'} />
      </BackActionIcon>
      <BackActionText>back</BackActionText>
    </BackActionStyle>
  );
};

const StickySearch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    searchValue,
    setSearchValue,
    clid,
    setEarnings,
    activePlatform,
    toggleActivePlatform,
    activeSortBy,
    toggleActiveSortBy,
    activeTab,
    toggleActiveTab,
  } = usePlaywallContext();

  const fetchEarnings = async (clid: string) => {
    try {
      const res = await fetch(`https://offers-api.santabrowser.com/${clid}/stats/summary`);
      const data: Earnings = await res.json();

      setEarnings(data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const [isSortOpen, setIsSortOpen] = React.useState(false);
  const [isPlatformOpen, setIsPlatformOpen] = React.useState(false);

  const popupRef = React.useRef<HTMLDivElement | null>(null);
  const popupRef2 = React.useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPlatformOpen(false);
    }

    if (popupRef2.current && !popupRef2.current.contains(event.target)) {
      setIsSortOpen(false);
    }
  };

  const handleTabClick = (event: any, index: number) => {
    event.target.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });

    navigate(`/${location.search}`);
    toggleActiveTab(index);
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  React.useEffect(() => {
    if (!clid) return;
    fetchEarnings(clid);
  }, []);

  return location.pathname.startsWith(earnings_route) ? (
    <StickySearchStyle top={'0px'}>
      <MobileView style={{ flexDirection: 'column' }}>
        <BackAction />
      </MobileView>
    </StickySearchStyle>
  ) : (
    <StickySearchStyle top={'0px'}>
      <MobileView style={{ flexDirection: 'column' }}>
        <Spacer height={'10px'} />

        <FilterContainer>
          <SearchBox strict clearable={searchValue.length > 0}>
            <SearchIcon fill={'var(--search-icon)'} />
            <Search
              placeholder='Search'
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target?.value?.replace(/^\s+/, ''));
              }}
            />
            <CloseIconWrapper
              onClick={() => {
                setSearchValue('');
              }}
            >
              <CloseIcon fill={'var(--search-icon)'} />
            </CloseIconWrapper>
          </SearchBox>

          {searchValue.length === 0 ? (
            <>
              <SortDowndrop ref={popupRef}>
                <DeviceDowndropItem
                  onClick={() => {
                    setIsPlatformOpen(!isPlatformOpen);
                    setIsSortOpen(false);
                  }}
                >
                  <SortDowndropWrapper>
                    <SortDowndropItemIcon>{activePlatform.icon}</SortDowndropItemIcon>
                    <SortDowndropItemName>{activePlatform.name}</SortDowndropItemName>
                  </SortDowndropWrapper>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    width={'12px'}
                    stroke='var(--text-dimmed)'
                    aria-hidden='true'
                    data-slot='icon'
                  >
                    <path stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5'></path>
                  </svg>
                </DeviceDowndropItem>
                {isPlatformOpen && (
                  <SortDowndropItems>
                    {platforms.map((platform, index) => {
                      return (
                        <DeviceDowndropItem
                          onClick={() => {
                            toggleActivePlatform(index);
                            setIsPlatformOpen(false);
                          }}
                        >
                          <SortDowndropWrapper>
                            <SortDowndropItemIcon>{platform.icon}</SortDowndropItemIcon>
                            <SortDowndropItemName>{platform.name}</SortDowndropItemName>
                          </SortDowndropWrapper>
                        </DeviceDowndropItem>
                      );
                    })}
                  </SortDowndropItems>
                )}
              </SortDowndrop>

              <SortDowndrop ref={popupRef2}>
                <SortDowndropItem
                  onClick={() => {
                    setIsSortOpen(!isSortOpen);
                    setIsPlatformOpen(false);
                  }}
                >
                  <SortDowndropWrapper>
                    {/* <SortDowndropItemFlag src={sortbys[activeSortIndex].image}></SortDowndropItemFlag> */}
                    <SortDowndropItemName>{activeSortBy.name}</SortDowndropItemName>
                  </SortDowndropWrapper>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    width={'12px'}
                    stroke='var(--text-dimmed)'
                    aria-hidden='true'
                    data-slot='icon'
                  >
                    <path stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5'></path>
                  </svg>
                </SortDowndropItem>
                {isSortOpen && (
                  <SortDowndropItems>
                    {sortbys.map((Sort, index) => {
                      return (
                        <SortDowndropItem
                          onClick={() => {
                            toggleActiveSortBy(index);
                            setIsSortOpen(false);
                          }}
                        >
                          <SortDowndropWrapper>
                            <SortDowndropItemName>{Sort.name}</SortDowndropItemName>
                          </SortDowndropWrapper>
                        </SortDowndropItem>
                      );
                    })}
                  </SortDowndropItems>
                )}
              </SortDowndrop>
            </>
          ) : null}
        </FilterContainer>

        <Spacer height={'10px'} />

        <TabScroll>
          <Tabs>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  active={tab.name === activeTab.name}
                  onClick={(event) => {
                    handleTabClick(event, index);
                  }}
                >
                  {tab.name}
                </Tab>
              );
            })}
          </Tabs>
        </TabScroll>

        <Spacer height={'10px'} />
      </MobileView>
    </StickySearchStyle>
  );
};

const Main = styled.main`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const TabScroll = styled.div`
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  padding: 2px 0;
`;

const Tab = styled.button<{ active: boolean }>`
  white-space: nowrap;
  height: 25px;
  font-size: 12px;
  padding: 0 10px;
  border-radius: 6px;
  border: none;
  color: ${(props) => (props.active ? 'var(--menu-tab-active-color)' : 'var(--menu-tab-color)')};
  background: ${(props) => (props.active ? 'var(--menu-tab-active-background)' : 'var(--menu-tab-background)')};
  box-shadow: var(--menu-tab-shadow);
`;

const AppView = styled.div`
  height: 100vh;
  overflow-y: none;

  @media screen and (max-width: 768px) {
    overflow-y: auto;
  }

  &::-webkit-scrollbar-track {
    margin-top: 60px;
  }

  @media screen and (max-width: 768px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default function App() {
  const { clid, getClid } = usePlaywallContext();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getClid().then(() => {
      setLoading(false);
    });
  }, [getClid]);

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <>
          {clid ? (
            <BrowserRouter>
              <Main>
                <AppView id='scrollableDiv'>
                  <AppHeader />

                  <StickySearch />

                  <BodyContainer>
                    <SideBar />

                    <Routes>
                      {ROUTE_CONFIG.map((item, index) => {
                        return <Route key={index} path={item.path} element={<item.Element />} />;
                      })}
                    </Routes>
                  </BodyContainer>
                </AppView>
              </Main>
            </BrowserRouter>
          ) : (
            <ErrorPage errorMessage={'Invalid CLID'} />
          )}
          ;
        </>
      )}
    </>
  );
}
