import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const TooltipTailBottom = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="21" viewBox="0 0 29 21" fill="none" {...props}>
    <path d="M13.0858 18.5858L10.249 15.749C8.86542 14.3654 8.17361 13.6736 7.36628 13.1789C6.6505 12.7402 5.87015 12.417 5.05386 12.221C4.13316 12 3.15479 12 1.19807 12H27.8019C25.8452 12 24.8668 12 23.9461 12.221C23.1299 12.417 22.3495 12.7402 21.6337 13.1789C20.8264 13.6736 20.1346 14.3654 18.751 15.749L15.9142 18.5858C15.1332 19.3668 13.8668 19.3668 13.0858 18.5858Z" fill='var(--coin-settings-tooltip-arrow)' />
    <path d="M27.8019 13C25.7976 13 24.9584 13.0064 24.1796 13.1934C23.4653 13.3649 22.7825 13.6477 22.1562 14.0315C21.4733 14.45 20.8753 15.0389 19.4581 16.4561L16.6213 19.2929C15.4497 20.4645 13.5503 20.4645 12.3787 19.2929L9.54193 16.4561C8.12466 15.0389 7.52672 14.45 6.84378 14.0315C6.21748 13.6477 5.53466 13.3649 4.82041 13.1934C4.04157 13.0064 3.20239 13 1.19807 13H0.5V12H1.19807C3.15479 12 4.13315 12 5.05386 12.221C5.87015 12.417 6.6505 12.7402 7.36628 13.1789C8.17361 13.6736 8.86542 14.3654 10.249 15.749L13.0858 18.5858C13.8668 19.3668 15.1332 19.3668 15.9142 18.5858L18.751 15.749C20.1346 14.3654 20.8264 13.6736 21.6337 13.1789C22.3495 12.7402 23.1299 12.417 23.9461 12.221C24.8668 12 25.8452 12 27.8019 12H28.5V13H27.8019Z" fill='var(--coin-settings-tooltip-border)' />
  </svg>
);

const StyledTooltip = styled.div<{ inverse?: boolean; isVisible: boolean; top: number; left: number; adjustWidth: boolean; }>`
  position: fixed;
  // min-width: 280px;
  width: fit-content;
  max-width: 240px;
  min-height: 40px;
  max-height: 119px;
  z-index: 999999;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  transform: translateX(-50%);
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  background-color: var(--popup-tooltip-background);
  color: var(--popup-tooltip-text-color);
  padding: 12px 16px;
  border-radius: 8px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: var(--popup-tooltip-border);
  word-wrap: break-word;
`;

const StyledTooltipTail = styled(TooltipTailBottom) <{ inverse?: boolean; }>`
  position: absolute;
  ${props => props.inverse ? 'bottom' : 'top'}: 98%;
  right: 0;
  left: 45%;
  transform: translateX(0%) ${props => props.inverse ? 'rotate(180deg)' : ''} translateY(-50%);
  z-index: 1000000;
`;

interface TooltipProps {
  children: React.ReactNode;
  text: string;
  adjustWidth?: boolean;
  inverse?: boolean;
}

const PopupTooltip: React.FC<TooltipProps> = ({ children, text, inverse, adjustWidth = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const updateTooltipPosition = () => {
    if (tooltipRef.current && triggerRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const triggerRect = triggerRef.current.getBoundingClientRect();

      let adjustedLeft = triggerRect.left + triggerRect.width / 2 + window.scrollX;

      if (adjustWidth) {
        adjustedLeft -= 100;
      }

      if (adjustedLeft + tooltipRect.width / 2 > window.innerWidth) {
        adjustedLeft = window.innerWidth - tooltipRect.width / 2;
      } else if (adjustedLeft - tooltipRect.width / 2 < 0) {
        adjustedLeft = tooltipRect.width / 2;
      }

      setPosition({
        top: inverse ? triggerRect.top + window.scrollY + 15 : triggerRect.top + window.scrollY - tooltipRect.height - 10,
        left: adjustedLeft,
      });
    }
  };

  React.useEffect(() => {
    const handleResize = () => {
      if (isVisible) updateTooltipPosition();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isVisible]);

  const handleMouseOver = () => {
    updateTooltipPosition();
    setIsVisible(true);
  };

  const handleMouseOut = () => {
    setIsVisible(false);
  };
  const tooltipElement = (
    <StyledTooltip
      ref={tooltipRef}
      inverse={inverse}
      isVisible={isVisible}
      top={position.top}
      left={position.left}
      adjustWidth={adjustWidth}
    >
      <StyledTooltipTail inverse={inverse} />
      {text}
    </StyledTooltip>
  );

  return (
    <>
      <div ref={triggerRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        {children}
      </div>
      {ReactDOM.createPortal(tooltipElement, document.body)}
    </>
  );
};

export default PopupTooltip;
