import styled from "styled-components";

const Button = styled.div`
    min-width: 200px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    background: var(--earning-btn-bg);
    padding: 14px;
    padding-right: 24px;
    transform: .2s;

    &:hover {
        background: var(--earning-btn-hover-bg);
    }

    @media screen and (max-width: 768px) {
        padding: 0 10px;
        width: fit-content;
        min-width: unset;
        border-radius: 6px;
        height: 30px;
    }
`;

const Total = styled.p`
    color: var(--earning-btn-color);
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: 14px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 13px;
        display: none;
    }

    span {
        font-size: 15px;
    }
`;

const Amount = styled.p`
    color: var(--earning-btn-color);
    font-size: 15px;
    font-weight: 600;

    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
`;

export {
    Button,
    Total,
    Amount
};