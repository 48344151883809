import styled, { css } from "styled-components";

const BodyContainer = styled.div`
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 1rem;
    width: 100%;

    & .SidebarOpen {
        transform: translateX(0);
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const BeforeMain = styled.main`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 80px);
    position: relative;
    overflow-x: hidden;

    @media (max-width: 768px) {
      height: unset;
      overflow-y: auto;
    }
`;

const SubTitle = styled.h2`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--text-normal);
`;

const Main = styled.main``;

const CenteredInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 50px;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
      bottom: 150px;
    }
`;

const sectionCSS = css`
    border-radius: 10px;
    padding: 1rem;
    background: var(--wrapper-bg);
    border: var(--wrapper-border);
    box-shadow: var(--wrapper-shadow);
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      padding: 0.5rem;
    }
`;

const SliderContainer = styled.div`
    ${sectionCSS}
`;

const Trending = styled.div`
  ${sectionCSS}
`;

const Best = styled.div`
  ${sectionCSS}
`;

const Top = styled.div`
    grid-area: top;
    ${sectionCSS}
`;

const EmptyWall = styled.div`
  height: calc(100vh - 170px);
  text-align: center;
  color: var(--text-normal);
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 250px);
  }
`;

export {
  BodyContainer,
  SliderContainer,
  BeforeMain,
  Main,
  SubTitle,
  CenteredInfo,
  Trending,
  Best,
  Top,
  EmptyWall
};