import React, { useCallback, useState } from 'react';
import SpinnerLoader from '../components/SpinnerLoader';
import { usePlaywallContext } from '../contexts';
import _ from 'lodash';

import { BeforeMain, Trending, SubTitle, Top, EmptyWall, SliderContainer } from '../styles/HomePageStyles';

import Wall from '../components/Wall';
import { OfferItem, OfferWall } from '../types';
import { addTo2DArray, beMapper, convertTo2DArray, DeviceType, getDeviceType } from '../utils';
import OfferPopup from '../components/OfferPopup';
import WallSlider from '../components/WallSlider';
import { useTrackPageview } from '../utils/tracker';
import AdBanner from '../components/AdBanner';
import { useIsMobile } from '../hooks/is-mobile';

const HomePage = React.forwardRef(() => {
  const { clid, activeTab, activePlatform, searchValue, activeSortBy } = usePlaywallContext();

  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState(false);
  const [pageNo, setPageNo] = React.useState(0);
  const [maxPageSize, setMaxPageSize] = React.useState(10);
  const [trendingOffers, setTrendingOffers] = useState<OfferItem[]>();
  const [bestOffers, setBestOffers] = useState<OfferItem[]>();
  const [topOffersMobile, setTopOffersMobile] = useState<OfferItem[][]>();
  const [topOffers, setTopOffers] = useState<OfferItem[]>();
  const [selectedOffer, setSelectedOffer] = React.useState<OfferItem>();
  const isMobile = useIsMobile();

  useTrackPageview();

  const fetchData = (pageNo: number, searchValueInput?: string) => {
    if (loading) return;

    setLoading(pageNo === 0);
    setPaging(pageNo > 0);

    const data: { [key: string]: string } = {};

    if (searchValueInput) {
      data['searchQuery'] = searchValueInput?.trim();
    }

    if (activeTab.name !== 'All') {
      data['categories'] = beMapper[activeTab.name];
    }

    if (activePlatform.id !== 'all') {
      data['devices'] = beMapper[activePlatform.id];
    }

    if (activeSortBy.id === 'popular') {
      data['sortBy'] = 'epc';
      data['orderBy'] = 'desc';
    }

    if (activeSortBy.id === 'high-reward') {
      data['sortBy'] = 'payout';
      data['orderBy'] = 'desc';
    }

    if (activeSortBy.id === 'low-reward') {
      data['sortBy'] = 'payout';
      data['orderBy'] = 'asc';
    }

    data['pageSize'] = '50';
    data['pageNo'] = pageNo.toString();
    if (clid) data['clid'] = clid;

    fetch(`https://offers-api.santabrowser.com/offers/list?` + new URLSearchParams(data))
      .then((res) => res.json())
      .then((data: OfferWall) => {
        if (pageNo === 0) {
          if (data.data) {
            if (isMobile) {
              setTopOffersMobile(convertTo2DArray(data.data));
            } else {
              setTopOffers(data.data);
            }
          } else setTopOffers([]);

          if (data.bestOfSanta && data.bestOfSanta.length > 0) setBestOffers(data.bestOfSanta);
          else setBestOffers([]);

          if (data.trending && data.trending.length > 0) {
            if (activeSortBy.id === 'high-reward') {
              setTrendingOffers(data.trending.sort((a, b) => b.payout - a.payout));
            } else if (activeSortBy.id === 'low-reward') {
              setTrendingOffers(data.trending.sort((a, b) => a.payout - b.payout));
            } else {
              setTrendingOffers(data.trending);
            }
          }

          // trending ISSUE
          if (searchValueInput) {
            setTrendingOffers([]);
          }
        } else {
          if (data.data) {
            if (isMobile && topOffersMobile) {
              setTopOffersMobile(addTo2DArray(topOffersMobile, data.data));
            } else if (topOffers) {
              setTopOffers(topOffers.concat(data.data));
            }
          }
        }

        setMaxPageSize(data.totalPages || 0);

        setLoading(false);
        setPaging(false);
      })
      .catch(() => {
        // renderReact({ fail: true, fail_message: e.message });
        setLoading(false);
        setPaging(false);
      });
  };

  const debouncedFetchData = useCallback(
    _.debounce((searchValue) => fetchData(0, searchValue), 500),
    [],
  );

  React.useEffect(() => {
    if (searchValue.length > 0) {
      debouncedFetchData(searchValue);
    } else {
      fetchData(0);
      setPageNo(0);
    }

    // Cancel the debounce on component unmount
    return () => {
      debouncedFetchData.cancel();
    };
  }, [searchValue, debouncedFetchData, activeTab, activePlatform, activeSortBy]);

  function loadMoreOffers() {
    const nextPage = pageNo + 1;
    if (nextPage > maxPageSize || paging || loading) return;

    fetchData(nextPage);
    setPageNo(nextPage);
  }

  const handleScroll = (event: any) => {
    const scrollRemaining =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop - event.currentTarget.clientHeight;

    if (scrollRemaining < 1 && searchValue === '') {
      loadMoreOffers();
    }
  };

  React.useEffect(() => {
    document.getElementById('scrollableDiv')?.addEventListener('scroll', handleScroll);

    return () => {
      document.getElementById('scrollableDiv')?.removeEventListener('scroll', handleScroll);
    };
  });

  const beforeMainRef = React.useRef<HTMLDivElement>(null);

  const deviceType = getDeviceType();

  React.useEffect(() => {
    if (deviceType !== DeviceType.Desktop) {
      return;
    }

    const checkIfScrollable = () => {
      if (beforeMainRef && beforeMainRef.current) {
        const isScrollable = beforeMainRef.current.scrollHeight > beforeMainRef.current.clientHeight;
        if (!isScrollable && topOffers?.length) {
          loadMoreOffers();
        }
      }
    };

    // Check on component mount
    checkIfScrollable();

    // Optionally, also check on window resize
    window.addEventListener('resize', checkIfScrollable);

    return () => {
      window.removeEventListener('resize', checkIfScrollable);
    };
  }, [topOffers]);

  return (
    <>
      {loading && <SpinnerLoader />}
      <BeforeMain onScroll={handleScroll} ref={beforeMainRef}>
        {!loading && (
          <div style={{ position: 'relative' }}>
            {(trendingOffers?.length || -1) > 0 &&
              activeSortBy.id === 'popular' &&
              (activeTab.name === 'All' || activeTab.name === 'Others') && (
                <SliderContainer>
                  <SubTitle> Trending </SubTitle>

                  <WallSlider
                    offers={trendingOffers}
                    flat={true}
                    platform={activePlatform.id}
                    singleRow={true}
                    mini={false}
                    width='165px'
                    onClickOffer={setSelectedOffer}
                  />

                  <AdBanner src={'/images/ads_1.png'} alt={'Ads'} />
                </SliderContainer>
              )}

            {(bestOffers?.length || -1) > 0 && (activeTab.name === 'All' || activeTab.name === 'Others') && (
              <Trending>
                <SubTitle> Best of Santa </SubTitle>
                <WallSlider
                  offers={bestOffers}
                  singleRow={true}
                  platform={activePlatform.id}
                  flat={true}
                  mini={true}
                  width='120px'
                  onClickOffer={setSelectedOffer}
                />
              </Trending>
            )}

            <Top>
              <SubTitle>Top Offers</SubTitle>

              {isMobile &&
                topOffersMobile &&
                topOffersMobile.map((offers, index) => {
                  return (
                    <>
                      <Wall
                        key={index}
                        offers={offers}
                        flat={false}
                        mini={true}
                        platform={activePlatform.id}
                        width='120px'
                        onClickOffer={setSelectedOffer}
                      />

                      {index < topOffersMobile.length - 1 ? (
                        <>
                          {index % 2 === 0 ? (
                            <AdBanner src={'/images/ads_1.png'} alt={'Ads'} />
                          ) : (
                            <AdBanner src={'/images/ads_1.png'} alt={'Ads'} />
                          )}
                        </>
                      ) : null}
                    </>
                  );
                })}

              {!isMobile && (
                <Wall
                  offers={topOffers}
                  flat={false}
                  mini={true}
                  platform={activePlatform.id}
                  width='120px'
                  onClickOffer={setSelectedOffer}
                />
              )}

              {isMobile && topOffersMobile && (
                <>
                  {searchValue.length > 0 && topOffersMobile.length === 0 && !loading && (
                    <EmptyWall>No search result found!</EmptyWall>
                  )}
                </>
              )}

              {!isMobile && topOffers && (
                <>
                  {searchValue.length > 0 && topOffers.length === 0 && !loading && (
                    <EmptyWall>No search result found!</EmptyWall>
                  )}
                </>
              )}

              {isMobile && topOffersMobile && (
                <>
                  {searchValue.length === 0 && topOffersMobile.length === 0 && !loading && (
                    <EmptyWall>No offers available</EmptyWall>
                  )}
                </>
              )}

              {!isMobile && topOffers && (
                <>
                  {searchValue.length === 0 && topOffers.length === 0 && !loading && (
                    <EmptyWall>No offers available</EmptyWall>
                  )}
                </>
              )}
            </Top>

            {pageNo < maxPageSize && paging && (
              <div className='pager'>
                <SpinnerLoader plain />
              </div>
            )}
          </div>
        )}
      </BeforeMain>

      <OfferPopup offer={selectedOffer} setOffer={setSelectedOffer} />
    </>
  );
});

HomePage.displayName = 'HomePage';
export default HomePage;
