import React, { createContext, useContext, useState, ReactNode } from 'react';
import { AllIcon2, AndroidIcon, IosIcon, DesktopIcon, AllIcon, AppIcon, DepositeIcon, GameIcon, OthersIcon, PurchaseIcon, QuizIcon, SignUpIcon, SurveyIcon } from '../components/icons';
import { Earnings, Platform, SortBy, Tab } from '../types';
import { defaultTab } from '../utils';

const NTP_EXTENSION_ID = 'ehlpnjcddkggjcbeonecfdfdbeiiopoh';

export const platforms: Platform[] = [
    { name: 'All', icon: <AllIcon2 />, id: 'all' },
    { name: 'Android', icon: <AndroidIcon />, id: 'and' },
    { name: 'iOS', icon: <IosIcon />, id: 'ios' },
    { name: 'Desktop', icon: <DesktopIcon />, id: 'win' }
];

export const sortbys: SortBy[] = [
    { id: 'popular', name: 'Popular' },
    { id: 'high-reward', name: 'Highest Reward' },
    { id: 'low-reward', name: 'Lowest Reward' }
];

export const tabs: Tab[] = [
    { name: 'All', icon(fill: string) { return <AllIcon fill={fill} />; } },
    { name: 'Sign Up', icon(fill: string) { return <SignUpIcon fill={fill} />; } },
    { name: 'Game', icon(fill: string) { return <GameIcon fill={fill} />; } },
    { name: 'App', icon(fill: string) { return <AppIcon fill={fill} />; } },
    { name: 'Deposit', icon(fill: string) { return <DepositeIcon fill={fill} />; } },
    { name: 'Purchase', icon(fill: string) { return <PurchaseIcon fill={fill} />; } },
    { name: 'Quiz', icon(fill: string) { return <QuizIcon fill={fill} />; } },
    { name: 'Survey', icon(fill: string) { return <SurveyIcon fill={fill} />; } },
    { name: 'Others', icon(fill: string) { return <OthersIcon fill={fill} />; } },
];

interface PlaywallContextType {
    clid: string | null;
    getClid: () => Promise<void>;
    activeTab: Tab;
    toggleActiveTab: (tabIndex: number) => void;
    searchValue: string;
    setSearchValue: (searchValue: string) => void;
    isSidebarOpen: boolean;
    setIsSidebarOpen: (isSidebarOpen: boolean) => void;
    activePlatform: Platform;
    toggleActivePlatform: (platformIndex: number) => void;
    activeSortBy: SortBy;
    toggleActiveSortBy: (sortbyIndex: number) => void;
    earnings: Earnings | null;
    setEarnings: (earnings: Earnings | null) => void;
}

const PlaywallContext = createContext<PlaywallContextType | undefined>(undefined);

interface PlaywallContextProviderProps {
    children: ReactNode;
}

function getCookieValue(name: string): string | null {
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find(row => row.startsWith(`${name}=`));
    return cookie ? cookie.split('=')[1] : null;
}

export const PlaywallProvider: React.FC<PlaywallContextProviderProps> = ({ children }) => {
    const [activeTab, setActiveTab] = useState<Tab>(defaultTab());
    const [searchValue, setSearchValue] = useState<string>('');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activePlatform, setActivePlatform] = useState<Platform>(platforms[0]);
    const [activeSortBy, setActiveSortBy] = useState<SortBy>(sortbys[0]);
    const [earnings, setEarnings] = React.useState<Earnings | null>(null);
    const [clid, setClid] = React.useState<string | null>(null);

    const toggleActiveTab = (tabIndex: number) => {
        setActiveTab(tabs[tabIndex]);
    };

    const toggleActivePlatform = (platformIndex: number) => {
        setActivePlatform(platforms[platformIndex]);
    };

    const toggleActiveSortBy = (sortbyIndex: number) => {
        setActiveSortBy(sortbys[sortbyIndex]);
    };

    const getClid = async (): Promise<void> => {
        return new Promise((resolve) => {
            // Search for clid value from document.
            const clidFromCookies = getCookieValue('clid');

            // If clid value not exists.
            if (!clidFromCookies) {
                // Send message to NTP extension.
                if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
                    chrome.runtime.sendMessage(NTP_EXTENSION_ID, { message: 'getClid' }, (response) => {
                        if (chrome.runtime.lastError || !response || !response.clid) {
                            setClid(null);
                        } else {
                            setClid(response.clid);
                        }
                        resolve();
                    });
                } else {
                    setClid(new URLSearchParams(window.location.search).get('uuid'));
                    resolve();
                }
            } else {
                // Else set clid to clid value from cookie.
                setClid(clidFromCookies);
                resolve();
            }
        });
    };

    return (
        <PlaywallContext.Provider value={{
            clid,
            getClid,
            activeTab,
            toggleActiveTab,
            searchValue,
            setSearchValue,
            activePlatform,
            toggleActivePlatform,
            activeSortBy,
            toggleActiveSortBy,
            isSidebarOpen,
            setIsSidebarOpen,
            earnings,
            setEarnings
        }
        }>
            {children}
        </PlaywallContext.Provider>
    );
};

export const usePlaywallContext = () => {
    const context = useContext(PlaywallContext);
    if (!context) {
        throw new Error('usePlaywallContext must be used within a PlaywallProvider');
    }
    return context;
};
