import styled from "styled-components";
import SpinnerLoader from "./SpinnerLoader";

const SplashContainer = styled.div`
    height: 100vh;
    width: 100vw;
`;

const SplashScreen = () => {
    return <SplashContainer>
        <SpinnerLoader />
    </SplashContainer>;
};

export default SplashScreen;