import {
    OfferContainer
} from "../styles/WallStyles";
import { WallProps } from "../types";
import { CenteredInfo } from "../styles/HomePageStyles";
import OfferWallWrapper from "./Offer";

export default function Wall(props: WallProps) {
    if (props.offers === undefined) {
        return <CenteredInfo>Loading...</CenteredInfo>;
    }

    return (
        <OfferContainer flat={props.flat} singleRow={props.singleRow} width={props.width}>
            {props.offers
                ?.filter(({ payout }) => payout > 0)
                .map((offer, index) => (
                    <OfferWallWrapper
                        key={index}
                        platform={props.platform}
                        mini={props.mini}
                        offer={offer}
                        flat={props.flat}
                        singleRow={props.singleRow}
                        onClickOffer={props.onClickOffer}
                        width={props.width}
                    />
                ))}
        </OfferContainer >
    );
}
