import styled, { css } from "styled-components";

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-area: header;
    gap: 16px;
    top: 0;
    z-index: 10;
    height: 60px;
    width: 100%;
    position: sticky;
    background: var(--full-bg);

    @media (max-width: 768px) {
      position: unset;
    }
    
    & > div {
        display: flex;
        align-items: center;
        gap: 10px;
    }    
`;

const HeaderWarpper = styled.div`
  cursor: pointer;
  min-width: 200px;

  @media (max-width: 768px) {
    min-width: 120px;
  }
`;

const QuestsIcon = styled.div`
    width: 40px;
    height: 40px;
    background-color: var(--logo-bg);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--logo-icon);
`;

const Title = styled.div`
    color: var(--logo-color);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;


const Toolbar = styled.div`
  display: flex !important; 
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  top: 0;
  position: sticky; 
  z-index: 3;

    @media (max-width: 1100px) {
       justify-content: flex-end;
    }
`;

const Search = styled.input`
    border: none;
    outline: none;
    height: 40px;
    background: none;
    color: var(--text-normal);
    width: 100%;
`;

const SearchBoxIcon = styled.div`
  border-radius: 20px;
  width: 38px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  border: var(--search-border);
  background: var(--search-bg);
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }
`;


const SearchBox = styled.div<{ clearable: boolean; strict?: boolean; }>`
  display: flex;
  align-items: center;
  gap: 10px;
  border: var(--search-border);
  background: var(--search-bg);
  width: 350px;
  max-width: 100%;
  height: 40px;
  border-radius: 12px;
  padding-left: 1.25rem;
  padding-right: .3rem;

  @media (max-width: 1300px) {
    width: 250px;
  }

  @media (max-width: 1000px) {
    width: 200px;
  }
    
  @media (max-width: 900px) {
    width: 150px;
  }

  @media (max-width: 850px) {
    width: 100px;
  }
    
  ${props => props.strict ?
    css`
      @media (max-width: 768px) {
        width: 100%;
      }
    ` :
    css`
      @media (max-width: 768px) {
        display: none;
      }
    `
  }

  & div:last-child {
    display: ${props => props.clearable ? `block` : `none`};
    cursor: pointer;
  }
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding-right: 60px;

    @media (max-width: 768px) {
        flex-direction: row;
        padding-right: 0;
    }
`;

const SortDowndrop = styled.div`
  border: var(--filter-border);
  background: var(--filter-bg);
  color: var(--text-normal);
  border-radius: 12px;  
  position: relative;

  @media (max-width: 768px) {
    border-radius: 6px;  
    }
`;

const SortDowndropItemName = styled.p`
  font-size: 12px;
  color: var(--filter-color);
  text-wrap: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1100px) {
    max-width: 50px;
    overflow: hidden;
  }

  @media (max-width: 500px) {
    max-width: 40px;
    overflow: hidden;
  }
`;

const SortDowndropItemIcon = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const SortDowndropWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media (max-width: 1100px) {
      gap: 0.5rem;
    }
`;

const DeviceDowndropItem = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  background: var(--filter-bg);
  border-radius: 12px;  
  width: fit-content;
  cursor: pointer;
  user-select: none;

  min-width: 145px;

  &:hover {
    background: var(--filter-bg-hover);
  }

  @media (max-width: 1100px) {
    padding: 0 1rem;
    gap: 0.5rem;
    height: 35px;
    
    min-width: 80px;
  }

    @media (max-width: 500px) {
    padding: 0 0.5rem;
    min-width: 60px;
    max-width: 100px;
    }
`;

const SortDowndropItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 1.25rem;
  background: var(--filter-bg);
  border-radius: 12px;  
  width: fit-content;
  cursor: pointer;
  user-select: none;

  min-width: 165px;

  &:hover {
    background: var(--filter-bg-hover);
  }
  
  @media (max-width: 1100px) {
    padding: 0 1rem;
    gap: 0.5rem;
    height: 35px;
   
    min-width: 100px;
  }

  @media (max-width: 768px) {
        min-width: 80px;
    }

    @media (max-width: 500px) {
      padding: 0 0.5rem;
      min-width: 60px;
    }
`;

const SortDowndropItems = styled.div`
  position: absolute;
  border-radius: 6px;  
  z-index: 20;
  top: 45px;
  right: 0;
  background: var(--dropdown-background);
  overflow: hidden;

  box-shadow: 0 0 #0000, 0 0 #0000, 0px 4px 24px 0px rgba(0, 0, 0, .12);

  > div {
    border-radius: 0;
    border-bottom: var(--filter-item-border);
    background: var(--filter-bg);
  }

  > div:last-child {
    border: none;
  }

  > div > div > p {
    color: var(--filter-color) !important;

    @media (max-width: 1100px) {
      min-width: 100px;
    }
  }
`;

const SiderbarFilterContainer = styled.div`
  gap: 10px;
  padding: 5px 0 10px 0;
  display: none;

  @media (max-width: 1100px) {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 40px 0.8fr 1fr;
    padding: 2px 0 5px 0;
  }

  .SortDowndrop2 {
    div, p {
      width: 100%;
      max-width: 100%;
    }
  }
`;

type SideBarProps = {
  active?: boolean;
};

const SideBar = styled.div<SideBarProps>`
    border-radius: 0.5rem;
    display: flex;
    width: 200px;
    max-width: 200px;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    flex: 1 0 0;
    top: 60px;
    height: 100%;
    transform: translateX(0);
    z-index: 5;
    @media (max-width: 768px) {
        transform: translateX(-113%);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 15;
        transition: transform .4s;
        max-width: 250px;
        width: 200px;
        background: var(--full-bg);
        ${({ active }) => {
    if (active) {
      return css`
                    transform: translateX(0);
                `;
    }
  }}
    }
`;

type SideBarOutsideProps = {
  active?: boolean;
};

const SideBarOutside = styled.div<SideBarOutsideProps>`
    display: none;
    //display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--sidebar-outside-bg);
    left: 0;
    top: 0;
    @media (max-width: 768px) {
        display: block;
        ${({ active }) => {
    if (active) {
      return css`
      width: 100vw;
                    left: 100%;`;
    }
  }
  }
    }
`;

type CategoryProps = {
  active: boolean;
};

const Category = styled.div<CategoryProps>`
    padding: 0.425rem 0.75rem;
    border-radius: 0.5rem;
    height: 34px;
    cursor: pointer;
    font-size: .75rem;
    color: var(--sidebar-item-color);
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--sidebar-item-background);
    border: var(--sidebar-item-border);
    width: 100%;

    &:hover {
        background: var(--sidebar-item-hover-background);
        color: var(--sidebar-item-hover-color);
    }

    &:hover > svg > rect  {
        stroke: var(--sidebar-item-hover-color);
    }

    &:hover > svg > path  {
        fill: var(--sidebar-item-hover-color);
    }

    &:hover > svg > g path  {
        stroke: var(--sidebar-item-hover-color);
    }

    &:nth-child(2):hover > svg > g path  {
        stroke: none;
        fill: var(--sidebar-item-hover-color);
    }

    &:nth-child(1):hover > svg > g path  {
        stroke: none;
        fill: var(--sidebar-item-hover-color);
    }

    & > svg {
        width: 24px;
    }

    ${({ active }) => {
    if (active) {
      return css`
            border: var(--sidebar-item-active-border);
            color: var(--sidebar-item-active-color);
            background: var(--sidebar-item-active-background);
        `;
    }
  }}
`;

const CategoryName = styled.p``;

export {
  Header,
  HeaderWarpper,
  QuestsIcon,
  Title,
  Toolbar,
  SearchBoxIcon,
  SearchBox,
  Search,
  CloseIconWrapper,
  FilterContainer,
  SortDowndrop,
  DeviceDowndropItem,
  SortDowndropWrapper,
  SortDowndropItemName,
  SortDowndropItemIcon,
  SortDowndropItems,
  SortDowndropItem,
  SideBar,
  SideBarOutside,
  Category,
  CategoryName,
  SiderbarFilterContainer
};